import { useState, useEffect, createContext, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";

export default function useSecurity() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const { authState: oktaAuthState, oktaAuth } = useOktaAuth() ?? {};

	const [authState, setAuthState] = useState(oktaAuthState);

	useEffect(() => {
		async function fetchData() {
			try {
				oktaAuth.authStateManager.subscribe(_authState => {
					setAuthState(_authState);
				});

				if (!oktaAuth.isLoginRedirect()) {
					setLoading(false);

					// Trigger an initial change event to make sure authState is latest when not in loginRedirect state
					oktaAuth.authStateManager.updateAuthState();
				}
			} catch (err) {
				setLoading(false);
				setError(err.message);
			}
		}
		fetchData();
	}, [oktaAuth]);

	const newContext = createContext({ authService: oktaAuth, authState });
	const result = useContext(newContext);

	return {
		loading,
		error,
		result,
	};
}
